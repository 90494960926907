<template>
    <Card title="Works">
        <form class="row g-3" @submit.prevent>
            <div class="col-12">
                <h3 class="float-start">{{ isEdit ? 'UPDATE' : 'NEW' }} WORKS</h3>
                <div class="float-end">
                    <button type="button" class="btn btn-outline-secondary btn-sm m-1" @click="back">BACK</button>
                    <button type="button" class="btn btn-outline-danger btn-sm m-1" @click="remove" v-if="isEdit">DELETE</button>
                    <button type="button" class="btn btn-outline-success btn-sm m-1" @click="handleSubmit">SAVE</button>
                </div>
            </div>
            <div class="col-12">
                <label for="title" class="form-label">Title</label>
                <input type="text" class="form-control" id="title" v-model="newItem.title">
            </div>
            <div class="col-12">
                <label for="text" class="form-label">About this project</label>
                <textarea class="form-control" id="text" rows="5" v-model="newItem.text"></textarea>
            </div>
            <div class="col-12">
                <label class="form-label">Technical Sheet</label>
                <div class="form-control">
                    <span v-for="techno in technos" :key="techno.id" class="badge rounded-pill m-1" :class="{'bg-danger': checkTechno(techno), 'bg-primary': !checkTechno(techno)}" role="button" @click="technoHandle(techno)"><h6 class="m-0"><i class="bi" :class="{'bi-dash-circle': checkTechno(techno), 'bi-plus-circle': !checkTechno(techno)}"></i> {{ techno.name }}</h6></span>
                    <!-- <span class="badge rounded-pill bg-danger m-1" role="button"><h6 class="m-0"><i class="bi bi-dash-circle"></i> Badge</h6></span> -->
                </div>
            </div>
            <div class="col-12">
                <label class="form-label">Resources</label>
                <div class="input-group">
                    <input type="text" class="form-control" placeholder="Title" v-model="resources.title">
                    <input type="text" class="form-control" placeholder="Name" v-model="resources.name">
                    <input type="text" class="form-control" placeholder="URL" v-model="resources.url">
                    <button class="btn btn-success" @click="addResource">ADD</button>
                </div>
            </div>
            <ul class="col-12">
                <li v-for="(item, index) in newItem.resources" :key="index">{{ item.title }} : <a :href="item.url" target="_blank" class="btn btn-link">{{ item.name }}</a> <button class="btn btn-link btn-sm" @click="deleteResource(index)"><i class="bi bi-trash text-danger"></i></button></li>
            </ul>
        </form>
        <div v-if="isEdit" class="row g-3">
            <div v-for="(image, index) in images" :key="index" class="col-2">
                <img :src="image.url" class="w-100">
                <button class="btn btn-outline-danger w-100" @click="removeIMG(image.name)">Delete</button>
            </div>
            <div class="col-4">
                <!-- <button class="btn btn-outline-success m-4">
                    <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" fill="currentColor" class="bi bi-plus-lg" viewBox="0 0 16 16">
                        <path d="M8 0a1 1 0 0 1 1 1v6h6a1 1 0 1 1 0 2H9v6a1 1 0 1 1-2 0V9H1a1 1 0 0 1 0-2h6V1a1 1 0 0 1 1-1z"/>
                    </svg>
                </button> -->
                <input type="file" class="from-control" @change="uploadIMG($event.target.files)">
            </div>
        </div>
    </Card>
</template>

<script>
import firebase from '../../../firebase'
import { required } from 'vuelidate/lib/validators'
import Card from '@/components/admin/Card.vue'
export default {
    components: { Card },
    props: [
        'item'
    ],
    data() {
        return {
            newItem: {
                title: '',
                text: '',
                technos: [],
                resources: [],
            },
            isEdit: false,
            technos: [],
            resources: {
                title: '',
                name: '',
                url: ''
            },
            images: []

        }
    },
    validation: {
        newItem: {
            title: { required },
        }
    },
    methods: {
        handleSubmit() {
            if(this.isEdit) {
                firebase.firestore().collection('projects').doc(this.item.id).set(this.newItem).then(() => this.back()).catch(err => console.error(err))
            } else {
                firebase.firestore().collection('projects').add(this.newItem).then(({ id }) => this.$router.push({ name: 'AdminWorksList' })).catch(err => console.error(err))
            }
        },
        remove() {
            firebase.firestore().collection('projects').doc(this.item.id).delete().then(() => this.back()).catch(err => console.error(err))
        },
        back() {
            this.$router.push({ name: 'AdminWorksList' })
        },
        getTechnos() {
            firebase.firestore().collection('technos').get().then(({ docs }) => {
                this.technos = docs.map(doc => {
                    return { ...doc.data(), id: doc.id }
                })
            }).catch(err => console.error(err))
        },
        checkTechno(techno) {
            return this.newItem.technos.some(t => t.id === techno.id)
        },
        technoHandle(techno) {
            if(this.checkTechno(techno)) this.newItem.technos = this.newItem.technos.filter(t => t.id !== techno.id)
            else this.newItem.technos.push(techno)
        },
        addResource() {
            this.newItem.resources.push({ ...this.resources })
            this.resources = { title: '', name: '', url: '' }
        },
        deleteResource(index) {
            this.newItem.resources = this.newItem.resources.filter((r, i) => i !== index)
        },
        getImages() {
            this.images = []
            firebase.storage().ref(`Images/Project/${this.item.id}`).listAll().then(({ items }) => {
                items.map(item => item.getDownloadURL().then(url => this.images.push({ name: item.name, url })))
            })
        },
        uploadIMG(files) {
            if(!files.length) return console.log('required!')
            firebase.storage().ref(`Images/Project/${this.item.id}/${files[0].name}`).put(files[0]).then(resp => console.log(resp)).catch(err => console.error(err))
        },
        removeIMG(img) {
            firebase.storage().ref(`Images/Project/${this.item.id}/${img}`).delete().then(this.getImages()).catch(err => console.error(err))
        }
    },
    mounted() {
        if(this.item) {
            this.isEdit = true
            this.newItem = { ...this.item }
            delete this.newItem.id
            this.getImages()
        }
        this.getTechnos()
    }
}
</script>

<style scoped>
.form-control:focus {
    box-shadow: none;
}

textarea {
    resize: none;
}
</style>